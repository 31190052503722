import { Outlet } from "react-router-dom"
import { LoggedInFooter } from "~/footers/logged-in-footer"
import LoggedInHeader from "~/headers/logged-in-header"

export const SandwichPageLayout: React.FC = () => (
  <div className="flex min-h-screen flex-col">
    <LoggedInHeader />

    <main className="flex-1 overflow-y-auto p-4">
      <div className="mx-auto w-full max-w-7xl px-4">
        <Outlet />
      </div>
    </main>

    <LoggedInFooter />
  </div>
)
