import { zodResolver } from "@hookform/resolvers/zod"
import React from "react"
import { useForm } from "react-hook-form"
import { Link, useNavigate } from "react-router-dom"
import * as z from "zod"
import { gql } from "~/__generated__"
import { loginPath } from "~/common/paths"
import { useSafeMutation } from "~/common/use-safe-mutation"
import { TextField } from "~/fields/text-field"
import { Button } from "~/ui/button"
import { Form } from "~/ui/form"
import { useToast } from "~/ui/use-toast"

const signupFormSchema = z.object({
  firstName: z.string().min(1, "First name cannot be empty"),
  lastName: z.string().min(1, "Last name cannot be empty"),
  email: z.string().email({ message: "Invalid email address" }),
})

type SignupFormValues = z.infer<typeof signupFormSchema>

const createUserMutation = gql(/* GraphQL */ `
  mutation UserCreate($input: UserCreateInput!) {
    userCreate(input: $input) {
      user {
        id
      }
    }
  }
`)

export const SignupScreen: React.FC = () => {
  const [createUser, { loading }] = useSafeMutation(createUserMutation)
  const { toast } = useToast()
  const navigate = useNavigate()

  const signupForm = useForm<SignupFormValues>({
    resolver: zodResolver(signupFormSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
    },
  })

  const onSignupSubmit = async (values: SignupFormValues) => {
    const result = await createUser({
      variables: {
        input: {
          ...values,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      },
    })

    if (result.errors) {
      console.error("Signup failed:", result.errors)
      toast({
        title: "Signup Failed",
        description: "An error occurred during signup. Please try again.",
        variant: "destructive",
      })
      return
    }

    if (result.data?.userCreate.user.id) {
      toast({
        title: "Account created",
        description: "Please sign in using your email to receive a login link.",
        variant: "default",
      })
      navigate(loginPath({}))
    } else {
      toast({
        title: "Signup Failed",
        description: "Unable to create an account. Please try again.",
        variant: "destructive",
      })
    }
  }

  return (
    <div className="flex min-h-screen flex-col items-center justify-center bg-background">
      <div className="w-full max-w-md space-y-8">
        <h1 className="text-center text-2xl font-bold">Sign up</h1>
        <Form {...signupForm}>
          <form onSubmit={signupForm.handleSubmit(onSignupSubmit)} className="space-y-2">
            <TextField
              control={signupForm.control}
              name="firstName"
              label="First name"
              placeholder="Enter your first name"
            />
            <TextField
              control={signupForm.control}
              name="lastName"
              label="Last name"
              placeholder="Enter your last name"
            />
            <TextField
              control={signupForm.control}
              name="email"
              label="Email"
              placeholder="Enter your email"
            />
            <Button type="submit" className="w-full" disabled={loading}>
              {loading ? "Creating account …" : "Sign up"}
            </Button>
          </form>
        </Form>

        <div className="space-y-2 text-center">
          Already have an account?
          <Link to={loginPath({})} className="block text-sm text-blue-600 hover:underline">
            Sign in
          </Link>
        </div>
      </div>
    </div>
  )
}
