import linkIcon from "~/images/link-icon.svg"

import { formatDistanceToNow } from "date-fns"
import { gql } from "~/__generated__"
import { BookmarkIndexItemFragment } from "~/__generated__/graphql"
import { UserAvatar } from "~/users/user-avatar"

gql(/* GraphQL */ `
  fragment BookmarkIndexItem on Bookmark {
    id
    createdAt
    description
    title
    url
    user {
      id
      firstName
      fullName
      lastName
    }
  }
`)

type BookmarkIndexItemParams = {
  bookmark: BookmarkIndexItemFragment
}

export const BookmarkIndexItem: React.FC<BookmarkIndexItemParams> = ({ bookmark }) => {
  return (
    <li className="mt-8 border-t pt-8">
      <div className="mb-2 flex text-xs">
        <div className="flex flex-1 space-x-2">
          <div className="">
            <UserAvatar user={bookmark.user} className="h-8 w-8" />
          </div>
          <div className="flex flex-col justify-center align-middle">
            <div>
              <span className="text-gray-400">by</span> {bookmark.user?.fullName}
            </div>
          </div>
        </div>
        <div className="flex-1 text-right text-gray-400">
          {formatDistanceToNow(new Date(bookmark.createdAt), { addSuffix: true })}
        </div>
      </div>
      <div className="flex">
        <div className="flex-1">
          <h2 className="flex space-x-2 text-lg font-medium">
            <a href={bookmark.url} className="hover:text-blue-500">
              {bookmark.title}
            </a>
            <a href={bookmark.url} className="self-center">
              <img src={linkIcon} className="rounded-full bg-gray-200" width={18} height={18} />
            </a>
          </h2>
          <p className="text-sm text-gray-400">{bookmark.description}</p>
        </div>
      </div>
    </li>
  )
}
