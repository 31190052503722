/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  query Users($first: Int = 20, $after: String) {\n    users(first: $first, after: $after) {\n      edges {\n        node {\n          id\n          fullName\n          email\n          createdAt\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n": types.UsersDocument,
    "fragment CachedViewerContext on User {\n  id\n  fullName\n  firstName\n  lastName\n  email\n  createdAt\n  userRole\n  avatarThumbUrl\n}": types.CachedViewerContextFragmentDoc,
    "\n  mutation Logout($input: LogoutInput!) {\n    logout(input: $input) {\n      success\n    }\n  }\n": types.LogoutDocument,
    "\n  query Viewer {\n    viewer {\n      id\n      ...CachedViewerContext\n    }\n  }\n": types.ViewerDocument,
    "\n  fragment BookmarkIndexItem on Bookmark {\n    id\n    createdAt\n    description\n    title\n    url\n    user {\n      id\n      firstName\n      fullName\n      lastName\n    }\n  }\n": types.BookmarkIndexItemFragmentDoc,
    "\n  query Bookmarks($after: String) {\n    bookmarks(first: 50, after: $after) {\n      edges {\n        node {\n          id\n          ...BookmarkIndexItem\n        }\n      }\n    }\n  }\n": types.BookmarksDocument,
    "\n  mutation BookmarkCreate($input: BookmarkCreateInput!) {\n    bookmarkCreate(input: $input) {\n      bookmark {\n        id\n      }\n    }\n  }\n": types.BookmarkCreateDocument,
    "\n  mutation CreateBookmarkEnrichment($input: BookmarkEnrichmentCreateInput!) {\n    bookmarkEnrichmentCreate(input: $input) {\n      bookmarkEnrichment {\n        id\n        description\n      }\n    }\n  }\n": types.CreateBookmarkEnrichmentDocument,
    "\n  subscription BookmarkEnrichmentUpdated($bookmarkEnrichmentId: ID!) {\n    bookmarkEnrichmentUpdated(bookmarkEnrichmentId: $bookmarkEnrichmentId) {\n      bookmarkEnrichment {\n        id\n        description\n        tags {\n          nodes {\n            id\n            name\n          }\n        }\n      }\n    }\n  }\n": types.BookmarkEnrichmentUpdatedDocument,
    "\n  mutation Login($input: CredentialsUserAuthInput!) {\n    login: credentialsUserAuth(input: $input) {\n      user {\n        id\n        firstName\n        lastName\n      }\n    }\n  }\n": types.LoginDocument,
    "\n  mutation EmailTokenAuth($input: EmailTokenUserAuthInput!) {\n    emailTokenUserAuth(input: $input) {\n      success\n      csrfToken\n    }\n  }\n": types.EmailTokenAuthDocument,
    "\n  mutation EmailAuthChallenge($input: EmailUserAuthChallengeInput!) {\n    emailUserAuthChallenge(input: $input) {\n      success\n    }\n  }\n": types.EmailAuthChallengeDocument,
    "\n  mutation UserUpdate($input: UserUpdateInput!) {\n    userUpdate(input: $input) {\n      user {\n        id\n        avatarThumbUrl\n      }\n    }\n  }\n": types.UserUpdateDocument,
    "\n  mutation UpdateProfile($input: UserUpdateInput!) {\n    userUpdate(input: $input) {\n      user {\n        id\n        firstName\n        lastName\n      }\n    }\n  }\n": types.UpdateProfileDocument,
    "\n  mutation UserCreate($input: UserCreateInput!) {\n    userCreate(input: $input) {\n      user {\n        id\n      }\n    }\n  }\n": types.UserCreateDocument,
    "\n  fragment UserAvatar on User {\n    id\n    firstName\n    lastName\n    avatarThumbUrl\n  }\n": types.UserAvatarFragmentDoc,
    "\n  query userDetails($userId: ID!) {\n    node(id: $userId) {\n      ... on User {\n        firstName\n        lastName\n        userRole\n        fullName\n        email\n      }\n    }\n  }\n": types.UserDetailsDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Users($first: Int = 20, $after: String) {\n    users(first: $first, after: $after) {\n      edges {\n        node {\n          id\n          fullName\n          email\n          createdAt\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n"): (typeof documents)["\n  query Users($first: Int = 20, $after: String) {\n    users(first: $first, after: $after) {\n      edges {\n        node {\n          id\n          fullName\n          email\n          createdAt\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "fragment CachedViewerContext on User {\n  id\n  fullName\n  firstName\n  lastName\n  email\n  createdAt\n  userRole\n  avatarThumbUrl\n}"): (typeof documents)["fragment CachedViewerContext on User {\n  id\n  fullName\n  firstName\n  lastName\n  email\n  createdAt\n  userRole\n  avatarThumbUrl\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation Logout($input: LogoutInput!) {\n    logout(input: $input) {\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation Logout($input: LogoutInput!) {\n    logout(input: $input) {\n      success\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Viewer {\n    viewer {\n      id\n      ...CachedViewerContext\n    }\n  }\n"): (typeof documents)["\n  query Viewer {\n    viewer {\n      id\n      ...CachedViewerContext\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment BookmarkIndexItem on Bookmark {\n    id\n    createdAt\n    description\n    title\n    url\n    user {\n      id\n      firstName\n      fullName\n      lastName\n    }\n  }\n"): (typeof documents)["\n  fragment BookmarkIndexItem on Bookmark {\n    id\n    createdAt\n    description\n    title\n    url\n    user {\n      id\n      firstName\n      fullName\n      lastName\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Bookmarks($after: String) {\n    bookmarks(first: 50, after: $after) {\n      edges {\n        node {\n          id\n          ...BookmarkIndexItem\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query Bookmarks($after: String) {\n    bookmarks(first: 50, after: $after) {\n      edges {\n        node {\n          id\n          ...BookmarkIndexItem\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation BookmarkCreate($input: BookmarkCreateInput!) {\n    bookmarkCreate(input: $input) {\n      bookmark {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation BookmarkCreate($input: BookmarkCreateInput!) {\n    bookmarkCreate(input: $input) {\n      bookmark {\n        id\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateBookmarkEnrichment($input: BookmarkEnrichmentCreateInput!) {\n    bookmarkEnrichmentCreate(input: $input) {\n      bookmarkEnrichment {\n        id\n        description\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateBookmarkEnrichment($input: BookmarkEnrichmentCreateInput!) {\n    bookmarkEnrichmentCreate(input: $input) {\n      bookmarkEnrichment {\n        id\n        description\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  subscription BookmarkEnrichmentUpdated($bookmarkEnrichmentId: ID!) {\n    bookmarkEnrichmentUpdated(bookmarkEnrichmentId: $bookmarkEnrichmentId) {\n      bookmarkEnrichment {\n        id\n        description\n        tags {\n          nodes {\n            id\n            name\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  subscription BookmarkEnrichmentUpdated($bookmarkEnrichmentId: ID!) {\n    bookmarkEnrichmentUpdated(bookmarkEnrichmentId: $bookmarkEnrichmentId) {\n      bookmarkEnrichment {\n        id\n        description\n        tags {\n          nodes {\n            id\n            name\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation Login($input: CredentialsUserAuthInput!) {\n    login: credentialsUserAuth(input: $input) {\n      user {\n        id\n        firstName\n        lastName\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation Login($input: CredentialsUserAuthInput!) {\n    login: credentialsUserAuth(input: $input) {\n      user {\n        id\n        firstName\n        lastName\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation EmailTokenAuth($input: EmailTokenUserAuthInput!) {\n    emailTokenUserAuth(input: $input) {\n      success\n      csrfToken\n    }\n  }\n"): (typeof documents)["\n  mutation EmailTokenAuth($input: EmailTokenUserAuthInput!) {\n    emailTokenUserAuth(input: $input) {\n      success\n      csrfToken\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation EmailAuthChallenge($input: EmailUserAuthChallengeInput!) {\n    emailUserAuthChallenge(input: $input) {\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation EmailAuthChallenge($input: EmailUserAuthChallengeInput!) {\n    emailUserAuthChallenge(input: $input) {\n      success\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UserUpdate($input: UserUpdateInput!) {\n    userUpdate(input: $input) {\n      user {\n        id\n        avatarThumbUrl\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UserUpdate($input: UserUpdateInput!) {\n    userUpdate(input: $input) {\n      user {\n        id\n        avatarThumbUrl\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateProfile($input: UserUpdateInput!) {\n    userUpdate(input: $input) {\n      user {\n        id\n        firstName\n        lastName\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateProfile($input: UserUpdateInput!) {\n    userUpdate(input: $input) {\n      user {\n        id\n        firstName\n        lastName\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UserCreate($input: UserCreateInput!) {\n    userCreate(input: $input) {\n      user {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UserCreate($input: UserCreateInput!) {\n    userCreate(input: $input) {\n      user {\n        id\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment UserAvatar on User {\n    id\n    firstName\n    lastName\n    avatarThumbUrl\n  }\n"): (typeof documents)["\n  fragment UserAvatar on User {\n    id\n    firstName\n    lastName\n    avatarThumbUrl\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query userDetails($userId: ID!) {\n    node(id: $userId) {\n      ... on User {\n        firstName\n        lastName\n        userRole\n        fullName\n        email\n      }\n    }\n  }\n"): (typeof documents)["\n  query userDetails($userId: ID!) {\n    node(id: $userId) {\n      ... on User {\n        firstName\n        lastName\n        userRole\n        fullName\n        email\n      }\n    }\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;