import { useState } from "react"
import { Link } from "react-router-dom"
import { useLogout } from "~/auth/use-logout"
import { useViewer } from "~/auth/use-viewer"
import { bookmarksPath, profilePath, todoPath } from "~/common/paths"
import bookmarkIcon from "~/images/bookmark-icon"
import groupsIcon from "~/images/groups-icon"
import logoIcon from "~/images/logo-icon"
import logoIconGray from "~/images/logo-icon-gray"
import { UserAvatar } from "~/users/user-avatar"

const Header = () => {
  const { viewer } = useViewer()
  const logout = useLogout()
  const [dropdownOpen, setDropdownOpen] = useState(false)

  return (
    <header className="sticky top-0 border-b border-gray-300">
      <div className="mx-auto px-4">
        <div className="flex h-16 items-center justify-between">
          {/* Left Side */}
          <div className="flex items-center space-x-6">
            {/* Logo and App Name */}
            <div className="flex items-center space-x-2">
              <img {...logoIcon} alt="Logo" />
              <span className="font-bold">Tas.ti</span>
            </div>

            {/* Links with Icons */}
            <nav className="flex items-center space-x-4">
              <Link to={bookmarksPath({})} className="flex items-center space-x-1">
                <img {...bookmarkIcon} alt="Bookmarks" />
                <span>Bookmarks</span>
              </Link>
              <Link to={todoPath({})} className="flex items-center space-x-1">
                <img {...logoIconGray} alt="History" />
                <span>History</span>
              </Link>
              <Link to={todoPath({})} className="flex items-center space-x-1">
                <img {...groupsIcon} alt="Groups" />
                <span>Groups</span>
              </Link>
            </nav>
          </div>

          {/* Right Side */}
          <div className="relative">
            <button
              onClick={() => setDropdownOpen((prev) => !prev)}
              className="flex items-center space-x-2"
            >
              <UserAvatar user={viewer} alt={`${viewer.fullName}'s Avatar`} />
              <span>{viewer.fullName}</span>
            </button>

            {/* Dropdown Menu */}
            {dropdownOpen && (
              <div className="absolute right-0 z-10 mt-2 w-48 rounded-md bg-white text-gray-800 shadow-lg">
                <Link to={profilePath({})} className="block px-4 py-2">
                  My Profile
                </Link>
                <button onClick={logout} className="block w-full px-4 py-2 text-left">
                  Sign Out
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
