import { useQuery } from "@apollo/client"
import { gql } from "~/__generated__"
import { GraphqlError } from "~/ui/errors"
import { BookmarkIndexItem } from "./bookmark-index-item"

const query = gql(/* GraphQL */ `
  query Bookmarks($after: String) {
    bookmarks(first: 50, after: $after) {
      edges {
        node {
          id
          ...BookmarkIndexItem
        }
      }
    }
  }
`)

export const BookmarksScreen = () => {
  // TODO: implement pagination
  const result = useQuery(query, { variables: { after: null } })

  if (result.error) {
    return <GraphqlError error={result.error} />
  }

  const nodes = result.data?.bookmarks.edges.map((e) => e.node) ?? []

  return (
    <div className="grid grid-cols-12">
      <div className="col-span-12">
        <h1 className="text-2xl">Bookmarks</h1>
        {result.loading ? (
          "…"
        ) : nodes.length ? (
          <ul>
            {nodes.map((bookmark) => (
              <BookmarkIndexItem bookmark={bookmark} />
            ))}
          </ul>
        ) : (
          <p>You don't have any saved bookmarks yet</p>
        )}
      </div>
    </div>
  )
}
